import $ from 'jquery';

export default class PhotoGrid {
  constructor() {
    $('.photo-grid .read-more').each((i, readMore) => {
      readMore = $(readMore);
      readMore.on('click', e => {
        console.log(readMore.data('content'));
        const container = $(readMore.data('content'));
        if (!container.hasClass('open')) {
          container.addClass('open');
          setTimeout(() => {
            container.find('.content').css('max-height', '4000px');
          }, 100);
        }
        else {
          container.find('.content').css('max-height', '0');
          setTimeout(() => {
            container.removeClass('open');
          }, 1000);
        }
        readMore.text(readMore.text() === 'Read More' ? 'Read Less' : 'Read More');
      });
    });

    $('.photo-grid .close-btn').each((i, close) => {
      close = $(close);
      close.on('click', e => {
        const container = close.closest('.photo-grid__article-content');
        container.find('.content').css('max-height', '0');
        setTimeout(() => {
          container.removeClass('open');
        }, 1000);
        $('.read-more[data-content="#' + container.attr('id') + '"').text('Read More');
      });
    });
  }
}

$(function () {
  new PhotoGrid();
});