import Tweezer from 'tweezer.js'

const curry = (f, ...args) => args.length >= f.length ? f(...args) : curry.bind(this, f, ...args)

const matchHeight = (items) => {
  let height = 0
  // get the highest height value
  items.forEach(function (item) {
    height = (height < item.offsetHeight) ? item.offsetHeight : height
  })

  // set the heights to the set value
  items.forEach((item) => {
    item.style.height = `${height}px`
  })
}

const doesSupportObjectFit = () => {
  const i = document.createElement('img')
  return ('objectFit' in i.style)
}

/**
 * Convert a template string into HTML DOM nodes
 * @param  {String} str The template string
 * @return {Node}       The template HTML
 */
const stringToHTML = str => {
	var parser = new DOMParser();
	var doc = parser.parseFromString(str, 'text/html');
	return doc.body;
}

const getUrlParameter = sParam => {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split('&');
  for (let i = 0; i < sURLVariables.length; i++) {
    let sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] == sParam) {
      return sParameterName[1];
    }
  }
  return false;
}

const makeId = (length = 8) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
 }
 return result
}

const formatDate = str => {
  // Remove ordinals.
  const str2 = str.replace(/(\d+)(st|nd|rd|th)/, '$1');
  const date = new Date(str2);
  const monthNames = [
    'January', 'February', 'March',
    'April', 'May', 'June', 'July',
    'August', 'September', 'October',
    'November', 'December',
  ];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  return `${monthNames[monthIndex]} ${day}, ${year}`;
}

const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

const isIOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const setCookie = (cname, cvalue, exdays = 30) => {
  var d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  var expires = "expires=" + d.toUTCString()
  document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
}

const getCookie = cname => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const checkCookie = cname => {
  const cvalue = getCookie(cname);
  return (cvalue && cvalue != '') ? true : false;
}

const getStyle = (el, style) => el ? window.getComputedStyle(el, null).getPropertyValue(style) : false

// return a float of a particular style setting. el is an element and style is the CSS property (eg: padding-left).
// returns false if the CSS setting is non-numeric
const getNumericStyle = (el, style) => parseFloat(getStyle(el, style).match(/\d+/)) ? parseFloat(getStyle(el, style).match(/\d+/)[0]) : false

const getParent = (elem, selector) => {
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (selector) {
      if (elem.matches(selector)) {
        return elem
      }
      continue
    }
  }
}

const getParents = (elem, selector) => {
  // Set up a parent array
  var parents = []

  // Push each parent element to the array
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (selector) {
      if (elem.matches(selector)) {
        parents.push(elem)
      }
      continue
    }
  }
  return parents
}

const getSibling = (elem, selector) => elem.parentNode.querySelector(selector)

const getWidth = () => {
  if (typeof window.innerWidth != 'undefined') {
    return window.innerWidth;
  }
  else if (typeof document.documentElement != 'undefined' && typeof document.documentElement.clientWidth != 'undefined' && document.documentElement.clientWidth != 0) {
    return document.documentElement.clientWidth;
  }
  else {
    return document.getElementsByTagName('body')[0].clientWidth;
  }
}

const toArray = (nodes) => {
  return Array.prototype.slice.call(nodes)
}

const scrollTo = (location, opts = {}, offset = 0, tickCallback = null, completedCallback = null) => {
  const config = {
    start: opts.start ? opts.start : window.pageYOffset,
    end: opts.end ? opts.end : location.getBoundingClientRect().top + window.pageYOffset - offset,
    duration: opts.duration ? opts.duration : 750,
    easing: opts.easing ? opts.easing : (t, b, c, d) => {
      if ((t/=d/2) < 1) return c/2*t*t + b
      return -c/2 * ((--t)*(t-2) - 1) + b
    }
  }
  const scroller = new Tweezer(config)
    .on('tick', v => {
      if(tickCallback) tickCallback()
      window.scrollTo(0, v)
    })
    .on('done', () => {
      if(completedCallback) completedCallback()
    })

  scroller.begin()
}

const getPosition = el => {
  let xPosition = 0;
  let yPosition = 0;

  while (el) {
    xPosition += (el.offsetLeft - el.scrollLeft + el.clientLeft);
    yPosition += (el.offsetTop - el.scrollTop + el.clientTop);
    el = el.offsetParent;
  }

  return { x: xPosition, y: yPosition };
}

// el: the element you want to see is above the bottom of the window
const isAboveBottom = (el, ) => {
  const rect = el.getBoundingClientRect()
  return rect.top + rect.height <= window.scrollY + window.innerHeight
}

const isInView = el => {
  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

const isInVerticalView = el => {
  const rect = el.getBoundingClientRect()
  const style = window.getComputedStyle(el)
  const display = style.getPropertyValue('display')
  // Make sure node is visible.
  if (display == 'none') {
    return false
  }
  return (
    rect.top >= 0 ||
    rect.bottom >= 0
  )
}

const isScrolledPast = (el, useParent = false) => {
  return useParent ? window.pageYOffset > (el.offsetParent.offsetTop + el.offsetHeight) : window.pageYOffset > (el.offsetTop + el.offsetHeight)
}

const getContrastColor = (rgbcolor, dark = 'black', light  = 'white') => {
	// RGB values
	let r = parseInt(rgbcolor[0]);
	let g = parseInt(rgbcolor[1]);
	let b = parseInt(rgbcolor[2]);

	// Get YIQ ratio
	let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

	// Check contrast
	return (yiq >= 128) ? dark : light;
}

const luminance = (r, g, b) => {
  var a = [r, g, b].map(v => {
    v /= 255;
    return v <= 0.03928 ?
      v / 12.92 :
      Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

const contrast = (rgb1, rgb2) => {
  var lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
  var lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
  var brightest = Math.max(lum1, lum2);
  var darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) /
    (darkest + 0.05);
}

const componentToHex = (c) => {
  var hex = c.toString(16)
  return hex.length == 1 ? 0 + hex : hex
}

const rgbToHex = (r, g, b) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const pipe = (...funcs) => function (val) { return funcs.reduce((acc, f) => f.apply(this, [acc]), val) }

const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export {
  curry,
  doesSupportObjectFit,
  stringToHTML,
  getCookie,
  setCookie,
  checkCookie,
  formatDate,
  getStyle,
  getNumericStyle,
  getWidth,
  getParent,
  getParents,
  getPosition,
  getSibling,
  getUrlParameter,
  getContrastColor,
  isAboveBottom,
  isInView,
  isInVerticalView,
  isSafari,
  isIOS,
  isScrolledPast,
  matchHeight,
  pipe,
  scrollTo,
  toArray,
  makeId,
  luminance,
  contrast,
  rgbToHex,
  debounce
}

