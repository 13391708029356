import $ from 'jquery';

export default class Nav {
  constructor() {
    const mainNav = $('.main-nav');
    const menuItems = $('.menu-items');
    const menuButton = $('.menu-button');

    menuButton.on('click', () => {
      if (mainNav.hasClass('open')) {
        menuItems.slideUp();
        mainNav.removeClass('open');
        menuButton.find('.label').text('Menu');
      }
      else {
        menuItems.slideDown();
        mainNav.addClass('open');
        menuButton.find('.label').text('Close');
      }
    });
  }
}

$(function () {
  new Nav();
});