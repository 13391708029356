import $ from 'jquery';
import MicroModal from 'micromodal';

export default class Video {
  constructor() {
    MicroModal.init({
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: false,
      awaitCloseAnimation: false,
      debugMode: false
    });

    $('.play-video').on('click', e => {
      MicroModal.show('video-modal');
      const iframe = $('#video-modal').find('iframe');
      const src = iframe.attr('src');
      iframe.attr('src', `${src}&autoplay=1`);
    });

    $('#video-modal .modal__close').on('click', e => {
      this.closeModal();
    });

    $(document).on('keydown', e => {
      if ($('#video-modal').hasClass('is-open') && e.keyCode === 27) {
        this.closeModal();
      }
    });
  }

  closeModal() {
    const iframe = $('#video-modal').find('iframe');
    const src = iframe.attr('src');
    iframe.attr('src', src.replace('&autoplay=1', ''));
    MicroModal.close('video-modal');
  }
}

$(function () {
  new Video();
});