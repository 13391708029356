import $ from 'jquery';
import { isAboveBottom, debounce } from "./lib/utils"

export default class Hero {
  constructor() {
    const counters = $('.hero .circle')
    counters.each((i, counter) => {
      this.startCounterIfInView($(counter))
      document.addEventListener('scroll', debounce(e => {
        this.startCounterIfInView($(counter))
      }, 50))
    })
  }

  startCounterIfInView(counter) {
    if (!counter.hasClass('counted') && isAboveBottom(counter[0])) {
      const limit = counter.data('limit')
      const percentage = counter.find('.percentage');
      let i = 0;
      const interval = setInterval(() => {
        if (i <= limit) {
          percentage.text(i++)
        } else {
          clearInterval(interval)
        }
      }, 25)
      counter.addClass('counted')
    }
  }
}

$(function () {
  new Hero();
});